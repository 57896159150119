import { usePlayer } from '@lib/hooks/use-player'

const Footer = () => {
  const { current } = usePlayer()
  return (
    <footer className={current ? 'playing' : ''}>
      <style jsx>{`
        footer {
          padding: 2rem 0;
          display: flex;
          justify-content: center;
          gap: 1rem;
        }

        @media screen and (min-width: 766px) {
          footer.playing > :global(.back-btn) {
            left: 1.5rem;
          }
        }
        @media screen and (min-width: 1024px) {
          footer.playing {
            padding: 2rem 21rem 2rem 1.5rem;
          }
        }
        @media screen and (min-width: 1440px) {
          footer.playing {
            padding: 2rem 23rem 2rem 1.5rem;
          }
        }
      `}</style>
    </footer>
  )
}

export default Footer
